.wrapper {
  background-color: #1B1B1A0D;
  padding: 20px 5px 15px 40px;

  .sub-component-title {
    font-size: 14px;
    font-weight: 600;
    color: #1b1b1a;
  }

  .sub-component-container {
    display: grid;
    grid-template-columns: auto 65px;

    .sub-component-add-icon {
      margin: 0 auto;
      padding-top: 10px;
    }

    .no-data-yet {
      color: #1b1b1a;
      font-weight: 500;
      letter-spacing: 0.48px;
      line-height: 3;
    }

    table {
      min-height: 60px;
      border-spacing: 2px;
      border-collapse: initial;
      background: #fff;
      margin-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 60px;

      thead tr th {
        color: #1B1B1AAB;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.48px;
        border: none;

        &:first-child {
          width: 300px;
        }

        &:nth-child(2) {
          width: 300px;
        }
      }

      tbody tr td {
        color: #1b1b1a;
        border: none;

        &.sub-component-delete {
          text-align: end;
          padding-right: 10px;
        }
      }
    }

    .deactivated-td {
      color: #f4516c;
    }
  }
}