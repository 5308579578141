.display-none {
  display: none !important;
}

span.thank_you_email {
  color: var(--primary-color) ;
  cursor: pointer;
}

.thank_you_email-content {
  min-width: 100%;
}

.thank_you_email-container {
  padding: 72px 170px 42px 170px;
  border-radius: 24px;
  background-color: #fff;
  max-width: 808px;
  max-height: 495px;
  margin: auto;

  .btn-upd-dashb {
    margin-bottom: 5%;
  }
}