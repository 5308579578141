.notFoundPage {
  .errorCode {
    font-size: 10rem;
    line-height: 20rem;
    color: #fff;
  }
  .errorHeader {
    font-size: 5rem;
    line-height: 2rem;
  }
  a {
    font-size: 2rem;
  }
}
