.cancel-button {
    display: inline-block;
    transition: none;
    text-align: center;
    background: transparent;
    white-space: nowrap;
    width: auto;
    height: 39px;
    padding: 0.65rem 1rem;
    margin: 0;
    border: 1px solid var(--primary-color);
    border-radius: 60px;
    font-size: 16px;
    font-weight: 600;
    line-height: 0.9;
    color: #1B1B1A;
    letter-spacing: 0.64px;
    cursor: pointer;

    &:hover:not(:disabled):not(.active) {
        background-color: var(--primary-color);
        color: #fff;
    }

    &:focus {
        border-color: var(--primary-color);
        background-color: var(--primary-color);
        color: #fff;
    }
}